import { useEffect, useState, createContext } from "react";
import { Button } from "antd";
import { getPageList } from "../apis/common";

const pageLanAll = {
  Cn: {
    app: {
      menu: [
        {
          label: "鼠标",
          key: 0,
          searchKey: "MOUSE",
        },
        {
          label: "键盘",
          key: 1,
          searchKey: "KEYBOARD",
        },
        {
          label: "周边",
          key: 2,
          searchKey: "PERIPHERY",
        },
        {
          label: "下载",
          path: "/download",
          key: 3,
        },
      ],
      lan: "Cn",
      search: "搜索",
      footer: {
        item1: "关注我们",
        item2: "商店",
        item3: "官方商城",
        item4: "京东自营官方旗舰店",
        item5: "支持",
        item6: "寻求帮助",
        item7: "售后服务与技术支持",
        item8: "质保条款",
      },
    },
    product: {
      buyText: "购买",
      technical: "技术规格",
    },
    search: {
      item1: `为 "`,
      item2: `" 找到`,
      item3: `个结果`,
    },
    periphery: {},
    download: {
      title: "欢迎来到狗邦自助服务",
      des: "我们会在这里提供帮助",
      product: "请选择产品",
      model: "请选择型号",
      prodTypeOptions: [
        { value: "MOUSE", label: "鼠标" },
        { value: "KEYBOARD", label: "键盘" },
        { value: "PERIPHERY", label: "周边" },
      ],
      columns: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "文件大小",
          dataIndex: "fileSize",
          key: "fileSize",
        },
        {
          title: "更新日期",
          dataIndex: "updateDate",
          key: "updateDate",
        },
        {
          title: "下载链接",
          dataIndex: "link",
          key: "link",
          width: "auto",
          render: (_, { link }) => (
            <>
              <Button
                className="download-table-btn-text"
                type="link"
                href={process.env.REACT_APP_BASE_API + link}
              >
                立即下载
              </Button>
            </>
          ),
        },
      ],
    },
  },
  En: {
    app: {
      menu: [
        {
          label: "Mouse",
          key: 0,
          searchKey: "MOUSE",
        },
        {
          label: "Keyboard",
          key: 1,
          searchKey: "KEYBOARD",
        },
        {
          label: "Others",
          key: 2,
          searchKey: "PERIPHERY",
        },
        {
          label: "Download",
          path: "/download",
          key: 3,
        },
      ],
      lan: "En",
      search: "Search",
      footer: {
        item1: "Follow Us",
        item2: "Shop",
        item3: "official mall",
        item4: "JD's self operated official flagship store",
        item5: "Support",
        item6: "help",
        item7: "after sales service and technical support",
        item8: "warranty terms",
      },
    },
    product: { buyText: "Buy", technical: "Technical" },
    search: {
      item1: `Found `,
      item2: ` results for the " `,
      item3: ` "`,
    },
    periphery: {},
    download: {
      title: "Welcome to Goubang Self Service",
      des: "We will provide assistance here",
      product: "Select a product",
      model: "Select a model",
      prodTypeOptions: [
        { value: "MOUSE", label: "Mouse" },
        { value: "KEYBOARD", label: "Keyboard" },
        { value: "PERIPHERY", label: "Periphery" },
      ],
      columns: [
        {
          title: "name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "file size",
          dataIndex: "fileSize",
          key: "fileSize",
        },
        {
          title: "update date",
          dataIndex: "updateDate",
          key: "updateDate",
        },
        {
          title: "link",
          dataIndex: "link",
          key: "link",
          width: "auto",
          render: (_, { link }) => (
            <>
              <Button
                className="download-table-btn-text"
                type="link"
                href={process.env.REACT_APP_BASE_API + link}
              >
                download
              </Button>
            </>
          ),
        },
      ],
    },
  },
};

export const LanContext = createContext();

export function LanProvider({ children }) {
  const [pageLan, setPageLan] = useState({});
  const [curPageLan, setCurPageLan] = useState("Cn");

  useEffect(() => {
    if (Object.keys(pageLan).length == 0) {
      const curPageLanStr = localStorage.getItem("page-lan") || "Cn";
      setCurPageLan(curPageLanStr);
      // setPageLan(pageLanAll[curPageLanStr]);
      getPageList({ type: "page" }).then((res) => {
        const tempConfCn = {};
        const tempConfEn = {};
        let swiperListCn = [];
        let swiperListEn = [];
        res.data.forEach((item) => {
          const { fieldKey } = item;
          if (fieldKey === "swiper") {
            const tempSwiperList = JSON.parse(item.fieldValue);
            tempSwiperList.forEach((sItem) => {
              const objCn = {};
              const objEn = {};
              for (const key in sItem) {
                if (key.indexOf("Cn") > -1) {
                  objCn[key.replace("Cn", "")] = sItem[key];
                }
                if (key.indexOf("En") > -1) {
                  objEn[key.replace("En", "")] = sItem[key];
                }
              }
              swiperListCn.push(objCn);
              swiperListEn.push(objEn);
            });
          } else if (fieldKey === "leftLogoImg") {
            // 左上角logo
            tempConfCn[fieldKey] = item.fieldValue;
            tempConfEn[fieldKey] = item.fieldValue;
          } else if (fieldKey.indexOf("bottomLink") > -1) {
            // 底部链接配置
            const val = JSON.parse(item.fieldValue);
            tempConfCn[fieldKey] = val?.map((item) => {
              return {
                name: item.nameCn,
                link: item.linkCn,
                icon: item.icon,
              };
            });
            tempConfEn[fieldKey] = val?.map((item) => {
              return {
                name: item.nameEn,
                link: item.linkEn,
                icon: item.icon,
              };
            });
          } else {
            if (fieldKey.indexOf("Cn") > -1) {
              tempConfCn[fieldKey.replace("Cn", "")] = item.fieldValue;
            }
            if (fieldKey.indexOf("En") > -1) {
              tempConfEn[fieldKey.replace("En", "")] = item.fieldValue;
            }
          }
        });
        pageLanAll.Cn.pageConf = tempConfCn;
        pageLanAll.Cn.swiperList = swiperListCn;
        pageLanAll.En.pageConf = tempConfEn;
        pageLanAll.En.swiperList = swiperListEn;
        setPageLan(pageLanAll[curPageLanStr]);
      });
    }
  }, []);

  // const changeLan = (lan) => {
  //   setCurPageLan(lan);
  //   setPageLan(pageLanAll[lan][path]);
  //   localStorage.setItem("page-lan", lan);
  // };

  const value = {
    pageLan,
    curPageLan,
    changeLan: (lan) => {
      setCurPageLan(lan);
      setPageLan(pageLanAll[lan]);
      localStorage.setItem("page-lan", lan);
    },
  };

  return <LanContext.Provider value={value}>{children}</LanContext.Provider>;
}
