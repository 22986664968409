import React, {
  useEffect,
  useState,
  createRef,
  useCallback,
  useContext,
} from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import routes from "./router/index";
import { Popover, Layout, Menu, Image, Input, Button } from "antd";
import { LanContext } from "./utils/lanContext";
import useStyle from "./utils/useStyle";
// import ScrollText from "./components/scrollText";
import TextShowBySingle from "./components/textShowBySingle";
import ProModal from "./components/proModal";
import logo from "./static/imgs/logo.png";
import sina from "./static/imgs/sina.png";
import wechat from "./static/imgs/wechat.png";
import tiktok from "./static/imgs/tiktok.png";
import bilibili from "./static/imgs/bilibili.png";
import search from "./static/imgs/search.png";
import "./App.scss";

const { Search } = Input;
const { Header, Content, Footer } = Layout;
export function App(props) {
  // 获取语言
  // const [pageLan.app?, changeLan] = useLanguage("app");
  const { pageLan, changeLan } = useContext(LanContext);
  // 设置字体样式
  useStyle();
  // 点击菜单modal展示位置
  const [pos, setPos] = useState([]);
  const [modalWidth, setModalWidth] = useState();
  const domApp = createRef();
  const imgPcRef = createRef();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(0);

  const handlePcMenuClick = ({ key }) => {
    if (!toUrl(key)) {
      setPos([0]);
    }
    // if (!toUrl(key)) {
    //   const { left, top } = imgPcRef.current.getBoundingClientRect();
    //   setPos([left, top + 80]);
    // }
  };
  // h5 点击菜单
  const handelH5MenuClick = ({ key }) => {
    if (!toUrl(key)) {
      setPos(["5%", 80]);
    }
  };

  // 窗口变化处理函数
  const handleWindowResize = useCallback(() => {
    if (!domApp.current) {
      return;
    }
    const { offsetWidth } = domApp.current;
    if (offsetWidth > 768 && modalWidth !== 600) {
      setModalWidth(900);
    } else if (offsetWidth <= 768 && modalWidth !== "90%") {
      setModalWidth("90%");
    }
    //domApp.current就是当前页面的dom元素，有了它就能计算尺寸
  }, [domApp]);

  // 菜单路由调整
  const toUrl = (key) => {
    setActiveKey(key);
    if ([3].findIndex((sKey) => sKey === key) > -1) {
      const menuItem = pageLan.app?.menu?.find((item) => item.key === key);
      navigate(menuItem?.path);
      return true;
    }
    return false;
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const onSearch = (value) => {
    navigate("/search?key=" + value);
  };
  const lanContent = (
    <div className="lan-content">
      <p onClick={() => changeLan("Cn")} className="pointer hover-white">
        中文
      </p>
      <p onClick={() => changeLan("En")} className="pointer hover-white">
        English
      </p>
    </div>
  );
  const searContent = (
    <div className="sear-content">
      <Search
        placeholder="Search dogbone.tech"
        onSearch={onSearch}
        style={{ width: 250 }}
      />
    </div>
  );
  return (
    <Layout className="App layout" ref={domApp}>
      <Header className="app-header-wrap">
        <div className="app-header">
          <div className="app-header-pc flex align-center justify-around">
            <div ref={imgPcRef}>
              <Image
                width={50}
                src={
                  process.env.REACT_APP_BASE_API + pageLan.pageConf?.leftLogoImg
                }
                preview={false}
                className="pointer"
                onClick={() => navigate("/home")}
              />
            </div>
            {pageLan.app?.menu?.map((item, index) => {
              return (
                <div
                  className={`pointer menu-item-text ${
                    activeKey === item.key ? "menu-item-text-active" : ""
                  }`}
                  key={item.key}
                  onClick={() => handlePcMenuClick(item)}
                >
                  <div>{item.label}</div>
                </div>
              );
            })}
            <div className="lan-search flex align-center">
              <Popover content={lanContent} title="">
                <div className="language pointer menu-item-text">
                  {pageLan.app?.lan}
                </div>
              </Popover>
              <div className="line" />
              <Popover content={searContent} title="">
                <div className="search pointer">
                  <img width={20} src={search} />
                </div>
              </Popover>
            </div>
          </div>
          <div className="app-heder-h5 flex justify-between">
            <Popover content={lanContent} title="">
              <div className="language pointer menu-item-text">
                {pageLan.app?.lan}
              </div>
            </Popover>
            <Image
              width={30}
              height={30}
              src={
                process.env.REACT_APP_BASE_API + pageLan.pageConf?.leftLogoImg
              }
              preview={false}
              className="pointer"
              onClick={() => navigate("/home")}
            />
            <Popover content={searContent} title="">
              <div className="search pointer">
                <img width={20} src={search} />
              </div>
            </Popover>
          </div>
        </div>
      </Header>
      <Content className="content">
        <div className="nav-h5 flex justify-around">
          {pageLan.app?.menu?.map((item, index) => {
            return (
              <div
                className={`pointer menu-item-text text-center ${
                  activeKey === item.key ? "menu-item-text-active" : ""
                }`}
                key={item.key}
                onClick={() => handelH5MenuClick(item)}
              >
                <div className="menu-item-text:hover">{item.label}</div>
              </div>
            );
          })}
        </div>
        <div className="scroll-wrap">
          {/* <ScrollText text="根据文本宽度计算动画持续时间根据文本宽度计算动画持续时间根据文本宽度计算动画持续时间" /> */}
          <TextShowBySingle />
        </div>
        {useRoutes(routes)}
      </Content>
      <Footer>
        <div className="footer flex">
          <div className="footer-item flex-twice">
            <div className="bottom-link-title-text">
              {pageLan.app?.footer?.item1}
            </div>
            {pageLan.pageConf?.bottomLinkFollow?.map((item, index) => {
              return (
                <div
                  className="pointer sub-item bottom-link-item-text"
                  key={index}
                  onClick={() => window.open(item.link)}
                >
                  {item.icon && (
                    <img
                      className="bottom-link-item-icon"
                      src={process.env.REACT_APP_BASE_API + item.icon}
                    />
                  )}
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
          <div className="footer-item flex-twice">
            <div className="bottom-link-title-text">
              {pageLan.app?.footer?.item2}
            </div>
            {pageLan.pageConf?.bottomLinkShop?.map((item, index) => {
              return (
                <div
                  className="pointer sub-item bottom-link-item-text"
                  key={index}
                  onClick={() => window.open(item.link)}
                >
                  {item.icon && (
                    <img
                      className="bottom-link-item-icon"
                      src={process.env.REACT_APP_BASE_API + item.icon}
                    />
                  )}
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
          <div className="footer-item flex-twice">
            <div className="bottom-link-title-text">
              {pageLan.app?.footer?.item5}
            </div>
            {pageLan.pageConf?.bottomLinkHelp?.map((item, index) => {
              return (
                <div
                  className="pointer sub-item bottom-link-item-text"
                  key={index}
                  onClick={() => window.open(item.link)}
                >
                  {item.icon && (
                    <img
                      className="bottom-link-item-icon"
                      src={process.env.REACT_APP_BASE_API + item.icon}
                    />
                  )}
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
          <div className="footer-item flex-treble">
            <div>
              <img
                className="move-img pointer"
                onClick={() => window.open(pageLan.pageConf?.rightImgLink)}
                src={
                  process.env.REACT_APP_BASE_API + pageLan.pageConf?.rightImg
                }
              />
            </div>
          </div>
        </div>
        <div className="flex center">
          {/* <Button
            type="link"
            onClick={() => window.open("https://beian.miit.gov.cn/")}
          >
            鄂ICP备2022005971号
          </Button> */}
          <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2022005971号-1</a>
        </div>
      </Footer>
      <ProModal
        pos={pos}
        modalWidth={modalWidth}
        curItem={pageLan.app?.menu[activeKey]}
      />
    </Layout>
  );
}
export default App;
